<template>

      <div class="card">
        <div class="mb-3" style="display: flex;border-radius: 4px">
            <FileUpload name="images[]" :maxFileSize="3145728" accept="image/*" mode="basic" :multiple="true" @select="selectFile" @clear="clearFile" chooseIcon="pi pi-times"  uploadIcon="pi pi-plus" :customUpload="true"  chooseLabel="选择文件">
            
            </FileUpload>
                <Button class="ml-2" type="button" v-html="loading" label="开始抠图" @click="uploadFile"></Button>
                <Button class="ml-2" type="button" @click="downloadAll" label="一键下载所有抠图"  />
        </div>
        <DataTable stripedRows :value="imageList" responsiveLayout="scroll" scrollable scrollHeight="calc(100vh - 14.3rem)">
            <Column header="原图预览">
                <template #body="{data}">
                    <img style="width:100px;height:100px;" :src="data.oriSrc" />
                </template>
            </Column>
            <Column header="抠图预览">
                <template #body="{data}">

                    <img v-if="data.newSrc" style="width:100px;height:100px;" :src="data.newSrc" />
                </template>
            </Column>
            <Column header="操作">
                <template #body="{data}">
                    <Button v-if="data.newSrc" @click="downloadIamge(data.newSrc,data.name)">下载抠图</Button>
                </template>
            </Column>
        </DataTable>
      </div>
</template>

<script>
import Service from '../service/Service';
    export default {
        data(){
            return{
                imageList:[],
                service:null,
                loading:'开始抠图'
            }
        },
        created() {
        this.service = new Service();
    },
        mounted(){
            var url='system/getsubaccountlist.php';
            this.service.sendHttp(url).then(res=>res.json()).then(data => {
                if(data.result=='success'){
                    this.userlist=data.users;
                } 
             });
        },
        methods:{
            selectFile(event){
                this.imageList=[];
                event.files.forEach(file => {
                    var src=window.URL.createObjectURL(file);
                    var image={name:file.name,file:file,oriSrc:src,newSrc:null};
                    this.imageList.push(image);
                });
            },
            clearFile(){
                this.imageList=[];
            },
            uploadFile(){
                this.loading='<i class="pi pi-spin pi-spinner"></i>&nbsp;正在抠图';
                this.imageList.forEach(file=>{
                   var url='getImage.php';
                   const formData = new FormData();
                   formData.append('files', file.file);
                   this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                        if(data.Data){
                            file.newSrc=data.Data.ImageURL;
                        }
                    });
                    this.sleep(1000);
                });
                this.loading='开始抠图';
            },
            sleep(time){
                var timeStamp = new Date().getTime();
                var endTime = timeStamp + time;
                while(new Date().getTime() < endTime){
                    continue;
                }
            },
            downloadIamge(imgsrc, name) {//下载图片地址和图片名
                var image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                    var a = document.createElement("a"); // 生成一个a元素
                    var event = new MouseEvent("click"); // 创建一个单击事件
                    a.download = name || "photo"; // 设置图片名称
                    a.href = url; // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event); // 触发a的单击事件
                };
                image.src = imgsrc;
            },
            downloadAll(){
                this.imageList.forEach(file=>{
                    if(file.newSrc!=null)
                    this.downloadIamge(file.newSrc,file.name);
                });
            }
        }
    }
</script>